<template>
  <div class="backdrop" v-if="isShow && isMain">
    <div class="popup-container">
      <div class="button-wrapper">
        <button
          class="close-button"
          aria-label="Close"
          @click="handleClosePopup"
        >
          <span aria-hidden="true">팝업닫기 &times;</span>
        </button>
      </div>
      <ul>
        <li
          v-for="banner in banners.twMainBanners"
          :key="banner.id"
          @click="handleMoveNoticeDetail(banner)"
        >
          <img :src="banner.twImage.url" alt="메인배너" />
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref, watch } from 'vue';
import { FetchBannerListResponse, TwMainBanner } from '@dto';
import { ROUTE_NAME } from '@enums';
import { AxiosAdapter, BannerAdapter } from '@adapters';
import { FetchBannerListUsecase } from '@useCases';
import { RouteLocationNormalized, useRouter } from 'vue-router';

const isShow = ref(true);
const isMain = ref(false);
const router = useRouter();
const banners = ref<FetchBannerListResponse>({
  total: 0,
  twMainBanners: [],
});

const handleClosePopup = () => {
  isShow.value = false;
};

const handleMoveNoticeDetail = (banner: TwMainBanner) => {
  const { link, postId, boardId } = banner;
  // link가 있으면 새탭으로 링크이동, 아닐 경우 내부 이동
  if (link) {
    window.open(link);
    return;
  }
  if (!postId || !boardId) return;
  router.push({
    name: boardId === 'news' ? ROUTE_NAME.NoticeView : ROUTE_NAME.ArchiveView,
    params: { postId },
  });
};

onMounted(async () => {
  isMain.value = router.currentRoute.value.name === ROUTE_NAME.Main;
  // checkDoNotShow();
  const axiosAdapter = new AxiosAdapter();
  const bannerAdapter = new BannerAdapter(axiosAdapter);
  const fetchBannerListUsecase = new FetchBannerListUsecase(bannerAdapter);
  banners.value = await fetchBannerListUsecase.execute();
});

watch(router.currentRoute, (value: RouteLocationNormalized) => {
  isMain.value = value.name === ROUTE_NAME.Main;
});
</script>

<style scoped lang="scss">
.backdrop {
  @apply flex fixed bg-black bg-opacity-80 w-screen h-screen left-0 top-0 justify-center items-center;
  animation: slideToDown 1.5s;
  z-index: 9999;
}
.button-wrapper {
  @apply flex justify-end w-full text-white fs-[14] mb-10;
}
.popup-container {
  @apply wi-[1080];
  ul {
    @apply flex flex-wrap gap-20;
    li {
      @apply cursor-pointer;
      width: calc((100% - 80px) / 5);
      img {
        @apply w-full;
      }
    }
  }
}

@keyframes slideToDown {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}
</style>
